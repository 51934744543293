<template>
    <div class="">

    <!-- navbar modals -->
    <div class="" :class="['bg-black top-0 bottom-0 w-screen z-40 opacity-50', {'fixed': navigator}]"></div>

    <div class="flex justify-center w-screen lg:hidden" :class="{'hidden': !navigator}">
        <div class="fixed border top-4 z-50 rounded-3xl w-11/12 bg-white">
            <div class="flex flex-row justify-between">
                
                <a href="#" class="px-3 py-4">
                    <img alt="Vue logo" src="../assets/logonada.svg" class="h-8 lg:h-9">
                </a>
                
                <div class="flex flex-row items-center p-6" @click="setNavigator(false)" >
                    <i class="far fa-lg fa-solid fa-times" ></i>
                </div>

            </div>
            <div class="flex flex-col">
                <a href="#features" class="h-14 w-full border-t block py-4 px-5" @click="setNavigator(false)">
                    <span class="text-base font-medium" >Features</span>
                </a>
                <a href="#usecase" class="h-14 w-full border-t border-b block py-4 px-5" @click="setNavigator(false)">
                    <span class="text-base font-medium">Use Case</span>
                </a>

                <router-link :to="{ name: 'demo_request'}">
                    <div class="h-14 w-full border-b block py-4 px-5">
                        <span class="text-base font-medium">Live Demo</span>
                    </div>
                </router-link>

                <div class="flex flex-col gap-4 p-6">
                    <a href="#contact" class="rounded-xl w-full bg-blue-500 border-blue-500 border text-sm text-center py-2 text-white" @click="setNavigator(false)">Contact Us</a>
                    <router-link :to="{ name: 'login'}" target="_blank"><button class="w-full rounded-xl py-2  border-blue-500 border text-sm text-blue-500 hover:text-blue-700 hover:border-blue-700">Sign In</button></router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white w-screen ring-decoration">
        <!-- <img alt="" src="../assets/BackgroundCircle.svg" class="absolute z-0 hidden lg:block" style="height: 1614px; width: 1614px; left:-171px; top:-427px;z-index:0"/> -->
        


        <section class="lg:py-24 lg:px-28 py-8">
            <div class="flex flex-row items-center justify-center ">
                <div class="fixed z-40 top-4 lg:top-custom-40 flex flex-row w-11/12 lg:w-5/6 border-solid border mx-auto rounded-full items-center justify-between text-slate-500 backdrop-filter backdrop-blur-lg px-5 lg:px-8 lg:py-3" :class="{ 'jumpUp': isNavbarHidden,'jumpDown': !isNavbarHidden}">
                    <div class="basis-1/2 flex flex-row items-center">
                        <a href="#" class="">
                            <!-- <img alt="Vue logo" :src="staticFilePath + 'images/logonada.svg'" class="h-8 lg:h-9"> -->
                            <img alt="Vue logo" src="../assets/logonada.svg" class="h-8 lg:h-9">
                        </a>
                        <a href="#features" class="ml-10 hover:text-zinc-900 text-sm hidden lg:block sm:hidden font-medium text-gray-500 hover:text-gray-700">Features</a>
                        <a href="#usecase" class="ml-8 hover:text-zinc-900 text-sm hidden lg:block sm:hidden font-medium text-gray-500 hover:text-gray-700">Use Case</a>
                        <router-link :to="{ name: 'demo_request'}"><div class="ml-8 hover:text-zinc-900 text-sm hidden lg:block sm:hidden font-medium text-gray-500 hover:text-gray-700">Live Demo</div></router-link>
                    </div>
                    
                    <div class="basis-1/4 lg:flex justify-center items-center">
                        <router-link :to="{ name: 'login'}" target="_blank"><button class="hidden sm:hidden lg:block mr-4 rounded-xl py-2 px-5 border-blue-500 border text-sm text-blue-500 font-medium hover:text-blue-700 hover:border-blue-700">Sign In</button></router-link>
                        <a href="#contact" class="hidden rounded-xl text-center py-2 px-5 bg-blue-500 border-blue-500 border text-sm text-white lg:block sm:hidden font-medium z-30 hover:bg-blue-700 hover:border-blue-700">Contact Us</a>
                        <div class="py-6 pr-l lg:hidden block" @click="setNavigator(true)">
                            <i class="fas fa-lg fa-bars"></i>
                        </div>
                    </div>
                </div>
            </div>
    
            <h1 class="lg:text-7xl px-5 text-5xl w-11/12 text-center mx-auto font-medium text-custom-blue-gray-900 pt-32 lg:pt-24">Monitor Water Levels<br/> with Precision</h1>
    
            <div class="mx-auto text-center lg:w-custom-50 w-11/12">
                <p class="mt-5 mb-10 text-gray-500 text-base lg:text-xl lg:leading-custom-1-875 text-center mx-auto">NADA’s Level Gauge delivers real-time water level measurements. It records the water level using a highly accurate radar sensor</p>
                <a href="#contact" class="rounded-xl font-medium bg-blue-500 border-blue-500 border text-sm text-white py-2 px-5 block lg:inline-block hover:bg-blue-700 hover:border-blue-700">Contact Us</a>
            </div>
            <div class="mx-auto h-96 lg:h-custom bg-sensor bg-center rounded-3xl lg:rounded-custom-4 mt-24 w-11/12 z-40"></div>
        </section>

        <section class="lg:py-24 py-8 lg:px-28">
            <div class="text-left text-5xl">
                <!-- <p class="mb-10 text-gray-500" ref="txt1"></p> -->
                <p class="text-center text-custom-blue-gray-500 lg:text-5xl text-2xl mb-12 lg:leading-custom-3-75 font-medium w-full mx-auto tracking-custom-[-0-06]">The Level Gauge measures the distance to the water surface using an industry-standard radar sensor. </p>
                <p class="text-center text-custom-blue-gray-500 lg:text-5xl text-2xl mb-12 lg:leading-custom-3-75 font-medium w-full mx-auto tracking-custom-[-0-06]" >It is mounted above the water surface, which avoids costly and labour-intensive underwater operations. </p>
                <p class="text-center text-custom-blue-gray-500 lg:text-5xl text-2xl mb-12 lg:leading-custom-3-75 font-medium w-full mx-auto tracking-custom-[-0-06]" >The 40 meter range of the Level Gauge will cover even the most extreme water level variations. </p>
            </div>
        </section>

    </div>

    <div class="w-screen bg-slate-400 lg:py-24 py-8" id="features">
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-4 sm:w-5/6 mx-auto">
            <div class="w-full lg:row-span-2 row-span-1 lg:col-span-1 col-span-2">
                <div class="w-11/12 mx-auto lg:w-full bg-white lg:rounded-custom-2-75 rounded-2xl mb-5" id="feature-1" :class="{'jumpDown': scrollPosOnFeature1, 'fade_out': !scrollPosOnFeature1}">
                    <div class="p-6 lg:p-10">
                        <h2 class="lg:text-center text-left text-xl lg:text-3xl font-medium lg:mb-3 mb-1 text-custom-blue-gray-900">Accurate Water Level Nada</h2>
                        <p class="lg:text-center text-left text-gray-500 text-sm lg:text-xl">Measures the distance to the water surface using an industry-standard radar system with signal-processing. Perfectly accurate</p>
                    </div>
                    <!-- <div class="border border-1 flex flex-row justify-center">
                        <lottie-vue-player
                        :src="`https://10.99.0.15:6969/images/chartanimation.json`"
                        :player-controls="false"
                        :loop="true"
                        :autoplay="true"
                        :showColorPicker="true"
                        style="width: 100%; height: 300px;"
                        ></lottie-vue-player>
                    </div> -->
                    <div ref="chartAnimation" id="chartAnimation" class="z-0"></div>
                </div>
                <div class="w-11/12 mx-auto lg:w-full bg-white lg:rounded-custom-2-75 rounded-2xl pb-16 lg:px-10" id="feature-2" :class="{'jumpDown': scrollPosOnFeature2, 'fade_out': !scrollPosOnFeature2}">
                    <div class="p-6 lg:p-10">
                        <h2 class="lg:text-center text-left text-xl lg:text-3xl font-medium lg:mb-3 mb-1 text-custom-blue-gray-900">Completely Wireless</h2>
                        <p class="lg:text-center text-left text-gray-500 text-sm lg:text-xl mt-3">No need to worry about internet access. Data are transmitted in real-time using a 4G GSM or Satellite connection</p>
                    </div>
                    <!-- <div class="flex flex-row justify-center">
                        <lottie-vue-player
                        :src="`https://lottie.host/ed50924e-65d7-429f-9562-c9749c6eebd6/5tnAJb3cp5.json`"
                        :player-controls="false"
                        :loop="true"
                        :autoplay="true"
                        :showColorPicker="true"
                        style="width: 100%; height: 500px;"
                        ></lottie-vue-player>
                    </div> -->
                    <div ref="wirelessAnimation" id="wirelessAnimation" class="z-0"></div>
                </div>
            </div>
            <div class="w-full lg:row-span-2 row-span-1 lg:col-span-1 col-span-2">
                <div class="w-11/12 mx-auto lg:w-full bg-white lg:rounded-custom-2-75 rounded-2xl mb-5" id="feature-3" :class="{'jumpDown': scrollPosOnFeature3, 'fade_out': !scrollPosOnFeature3}">
                    <div class="p-6 lg:p-10">
                        <h2 class="lg:text-center text-left text-xl lg:text-3xl font-medium lg:mb-3 mb-1 text-custom-blue-gray-900">Export Data</h2>
                        <p class="lg:text-center text-left text-gray-500 text-sm lg:text-xl">Stay compliant and be audit-ready – export accurate water level measurements in PDF.</p>
                    </div>
                    <img alt="Export" src="../assets/Export.svg" class="mx-auto">
                </div>
                <div class="w-11/12 mx-auto lg:w-full bg-white lg:rounded-custom-2-75 rounded-2xl mb-5" id="feature-4" :class="['', {'jumpDown': scrollPosOnFeature4, 'fade_out': !scrollPosOnFeature4}]">
                    <div class="p-6 lg:p-10">
                        <h2 class="lg:text-center text-left text-xl lg:text-3xl font-medium lg:mb-3 mb-1 text-custom-blue-gray-900">Real-Time Data</h2>
                        <p class="lg:text-center text-left text-gray-500 text-sm lg:text-xl">Receive live data updates no matter where you are. Keeps you connected in real-time.</p>
                    </div>
                    <img alt="Real Time Data" src="../assets/RealTimeData.svg" class="mx-auto">
                </div>
                <div class="w-11/12 mx-auto lg:w-full bg-white lg:rounded-custom-2-75 rounded-2xl" id="feature-5" :class="['', {'jumpDown': scrollPosOnFeature5, 'fade_out': !scrollPosOnFeature5}]">
                    <div class="p-6 lg:p-10">
                        <h2 class="lg:text-center text-left text-xl lg:text-3xl font-medium lg:mb-3 mb-1 text-custom-blue-gray-900">Solar Powered</h2>
                        <p class="lg:text-center text-left text-gray-500 text-sm lg:text-xl">No need to worry about access to mains power. Well-suited for monitoring remote locations.</p>
                    </div>
                    <img alt="Solar" src="../assets/Solar.svg" class="mx-auto w-full">
                </div>
            </div>
            <div class="w-11/12 mx-auto lg:w-full bg-white lg:rounded-custom-2-75 rounded-2xl p-6 lg:p-10 col-span-2" id="feature-6" :class="['', {'jumpDown': scrollPosOnFeature6, 'fade_out': !scrollPosOnFeature6}]">
                <h2 class="lg:text-center text-left text-xl lg:text-3xl font-medium lg:mb-3 mb-1 text-custom-blue-gray-900">Easy and Responsive Data Portal</h2>
                <p class="lg:text-center text-left text-gray-500 text-sm lg:text-xl lg:w-1/2 lg:mx-auto">The Data Portal lets you easily view, custom, download your water level data and make a PDF reports in any devices</p>
                <img alt="Laporan" src="../assets/Laporan.svg" class="mx-auto relative top-6" >
            </div>
        </div>
    </div>

    <div class="w-screen bg-white" id="usecase">
        <section class="lg:py-24 lg:px-28 py-24">
            <h1 class="text-center text-4xl mb-16 font-medium" :class="{'opacity_on': scrollPosOnUseCase, 'opacity_off': !scrollPosOnUseCase}">Nada Water Level Gauge in the field</h1>
            <div class="lg:block hidden">

                    <!-- <div class="grid grid-rows-1 grid-flow-col gap-0 lg:mx-auto border"> -->
                    <div class="flex flex-row justify-center lg:mx-auto">
                        <div class="my-desktop-card my-dim-catchmen-area ml-4 h-[31.25rem] rounded-3xl mb-5 border flex flex-row items-end justify-start group ">
                            <p class="opacity-0 group-hover:opacity-100 transition text-2xl text-white font-medium pb-3 pl-3">Catchment Area</p>
                        </div>
                        <div class="my-desktop-card my-dim-canal h-[31.25rem] rounded-3xl mb-5 border flex flex-row items-end justify-start group">
                            <p class="opacity-0 group-hover:opacity-100 transition text-2xl text-white font-medium pb-3 pl-3">Canal</p>
                        </div>
                        <div class="my-desktop-card my-dim-river h-[31.25rem] rounded-3xl mb-5 border flex flex-row items-end justify-start group">
                            <p class="opacity-0 group-hover:opacity-100 transition text-2xl text-white font-medium pb-3 pl-3">River</p>
                        </div>
                        <div class="my-desktop-card my-dim-harbour h-[31.25rem] rounded-3xl mb-5 border flex flex-row items-end justify-start group">
                            <p class="opacity-0 group-hover:opacity-100 transition text-2xl text-white font-medium pb-3 pl-3">Harbour</p>
                        </div>
                    </div>
            </div>
            
            <flickity ref="flickity" :options="flickityOptions" class="lg:w-3/4 mx-auto block lg:hidden">
                <div class="carousel-cell my-dim-catchmen-area w-56 h-96 rounded-lg mb-5 flex flex-row items-end justify-center pb-3">
                    <button class="mx-auto text-center rounded-xl bg-blue-400 text-white text-base px-2 py-1">
                        Catchmen Area
                    </button>
                </div>
                <div class="carousel-cell my-dim-river w-56 h-96 rounded-lg mb-5 flex flex-row items-end justify-center pb-3">
                    <button class="mx-auto text-center rounded-xl bg-blue-400 text-white text-base px-2 py-1">
                        River
                    </button>
                </div>
                <div class="carousel-cell my-dim-canal w-56 h-96 rounded-lg mb-5 flex flex-row items-end justify-center pb-3">
                    <button class="mx-auto text-center rounded-xl bg-blue-400 text-white text-base px-2 py-1">
                        Canal
                    </button>
                </div>
                <div class="carousel-cell my-dim-harbour w-56 h-96 rounded-lg mb-5 flex flex-row items-end justify-center pb-3">
                    <button class="mx-auto text-center rounded-xl bg-blue-400 text-white text-base px-2 py-1">
                        Harbour
                    </button>
                </div>
            </flickity>
            
        </section>
        <section class="lg:py-24 lg:px-28 pb-24" id="contact">
            <div class="flex lg:flex-row flex-col-reverse w-11/12 lg:w-full mx-auto ">
                <div class="basis-1/2 w-full flex items-center justify-center" :class="{'toCenter': scrollPosOnContact, 'fromLeft': !scrollPosOnContact}">
                    <div class="">
                        <h1 class="text-4xl lg:w-3/5 w-full font-medium text-custom-blue-gray-900 mt-10 lg:mt-0">Take control of your water management</h1>
                        <p class="text-base mt-3 lg:mt-3 mb-5 w-full lg:w-3/5 font-normal text-custom-blue-gray-500">Whether you have questions, feedback, or need support, our team is ready to help</p>
        
                        <div class="flex relative mt-3 lg:w-3/5 justify-end items-center">
                            <input class="rounded-xl h-12 lg:h-11 w-full py-2 px-3 mt-3 text-gray-500 border bg-gray-50 lg:bg-white text-sm leading-tight focus:outline-none focus:shadow-outline" id="fullname" name="fullname" type="text" placeholder="Full Name" v-model="form_name" :class="['border',{'border-red-500': (form_name.length > 0 || clickSubmitMessageBtn) && !isNameValid, 'border-custom-blue-gray-300': !((form_name.length > 0 || clickSubmitMessageBtn) && !isNameValid)}]">
                            <img alt="Danger Icon" class="absolute pr-3 top-6" src="../assets/danger_icon.svg"  v-if="(form_name.length > 0 || clickSubmitMessageBtn) && !isNameValid">
                        </div>
                        <p class="text-xs text-red-500 mt-1" v-if="(form_name.length == 0 && clickSubmitMessageBtn)">This field is required</p>
                        
                        <div class="flex relative mt-3 lg:w-3/5 justify-end items-center">
                            <input class="rounded-xl h-12 lg:h-11 py-2 px-3 w-full bg-gray-50 lg:bg-white text-gray-500 border-2 text-sm leading-tight focus:outline-none focus:shadow-outline" id="email" name="email" type="text" placeholder="Email" v-model="form_email" :class="['border',{'border-red-500': (form_email.length > 0 || clickSubmitMessageBtn) && !isEmailValid, 'border-custom-blue-gray-300': !((form_email.length > 0 || clickSubmitMessageBtn) && !isEmailValid)}]">

                            <img alt="Danger Icon" class="absolute pr-3" src="../assets/danger_icon.svg"  v-if="(form_email.length > 0 || clickSubmitMessageBtn) && !isEmailValid">
                        </div>
                        <p class="text-xs text-red-500 mt-1" v-if="(form_email.length > 0 || clickSubmitMessageBtn) && !isEmailValid">Email is not valid.</p>

                        <textarea rows="4" class="rounded-xl lg:w-3/5 w-full py-2 px-3 mt-3 bg-gray-50 lg:bg-white text-gray-500 border-2 text-sm leading-tight focus:outline-none focus:shadow-outline" id="message" type="text" name="message" placeholder="Tell us about NADA and provide some details about the device and challenge that NADA can solve." @input="handleInput" v-model="form_message" :class="['border',{'border-red-500': form_message.length >= 203 || (form_message.length == 0 && clickSubmitMessageBtn), 'border-custom-blue-gray-300': !(form_message.length >= 203 || (form_message.length == 0 && clickSubmitMessageBtn))}]"></textarea>
                        <p class="text-gray-500 text-xs" :class="{'text-red-500': form_message.length >= 203}">Character imit: {{ form_message.length }}/203</p>
                        <p class="text-xs text-red-500" v-if="(form_message.length == 0 && clickSubmitMessageBtn)">This field is required</p>
        
                        <button class="mr-3 rounded-xl py-2 lg:w-3/5 w-full lg:mt-5 mt-8 bg-blue-500 border-blue-500 border text-base font-medium text-white hover:bg-blue-700 hover:border-blue-700" @click="sendMail()">Contact Us</button>
    
                    </div>
                </div>
                <div class="basis-1/2 w-full" :class="{'opacity_on': scrollPosOnContact, 'opacity_off': !scrollPosOnContact}">
                    <img alt="Water Level Sensor Device" class="mx-auto" src="../assets/sensornada.png">
                </div>
            </div>
        </section>
    </div>
    <div class="rounded-t-custom-2-5 w-screen bg-blue-600 text-gray-200">
        <div class="mx-auto flex lg:flex-row gap-14 flex-col justify-between lg:px-28 lg:pt-16 lg:pb-12 px-5 py-10">
            <div class="lg:w-96 w-5/3">
                <a href="#" class="">
                    <img alt="Vue logo" src="../assets/logonadaputih.svg">
                </a>
                <p class="mt-6 lg:text-sm text-base">NADA is a high-quality system solutions that provide a smarter, integrated, and user-friendly approach to ecosystem observation</p>
            </div>
            <div>
                <div class="w-full flex lg:flex-row flex-col lg:gap-14 gap-12">
                    <div class="flex flex-col">
                        <a class="mb-3 text-base font-normal">Company</a>
                        <a href="#features" class="mb-3 text-sm hover:text-gray-50">Features</a>
                        <a href="#usecase" class="mb-3 text-sm hover:text-gray-50">Use Case</a>
                        <router-link :to="{ name: 'demo_request'}">
                            <a href="#" class="mb-3 text-sm hover:text-gray-50">Live Demo</a>
                        </router-link>
                    </div>
                    <div class="flex flex-col">
                        <a class="mb-3 text-base font-normal">Support</a>
                        <a href="#" class="mb-3 text-sm hover:text-gray-50">Terms of Condition</a>
                        <a href="#" class="mb-3 text-sm hover:text-gray-50">Privacy Policy</a>
                        <a href="#contact" class="mb-3 text-sm hover:text-gray-50">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="border-b text-center mx-5 lg:mx-28"></div>
        <div class="lg:px-28 px-5 py-12  flex lg:flex-row flex-col-reverse justify-between">
            <div class="font-normal text-sm">&copy; 2023 NADA, All rights reserved.</div>
            <div class="lg:mb-0 mb-6">
                <a href="#" class="inline-block mr-5">
                    <img alt="Vue logo" src="../assets/linkedin.svg">
                </a>
                <a href="#" class="inline-block">
                    <img alt="Vue logo" src="../assets/instagram.svg">
                </a>
            </div>
        </div>
    </div>
</div>
    
</template>
<style scoped>
    .bg-sensor{
        background-repeat: no-repeat;
        background-image: url("../assets/sensornada_hero.png") !important;
        background-size: cover;
    }
    .ring-decoration {
        background-image: url("../assets/BackgroundCircle.svg") !important;
        background-position-x: -599px;
        background-position-y: -579px;
        background-repeat: no-repeat;
    }
    .h-500{height: 500px;}
    .text-2rem {
        font-size: 2rem;
    }
    #wirelessAnimation{
        /* width: 23.3rem; */
        /* height: 23.3rem; */
        margin: auto;
    }
    .my-dim-canal{
        background-repeat: no-repeat;
        background-image: url("../assets/Canal.png") !important;
        background-size: cover;
        margin-right: 20px;
    }
    .my-dim-river{
        background-repeat: no-repeat;
        background-image: url("../assets/River.png") !important;
        background-size: cover;
        margin-right: 20px;
    }
    .my-dim-harbour {
        background-repeat: no-repeat;
        background-image: url("../assets/Harbour.png") !important;
        background-size: cover;
        margin-right: 20px;
    }
    .my-dim-catchmen-area {
        background-repeat: no-repeat;
        background-image: url("../assets/CatchmenArea.png") !important;
        background-size: cover;
        margin-right: 20px;
    }
    .my-desktop-card {
        width: 300px;
        transition: .5s;
    }
    .my-desktop-card:hover {
        width: 400px;
    }   
    /* landing animation */
    .jumpUp{transform: translateY(-100px);opacity: 0;transition: .5s ease-in-out;}
    .jumpDown{transform: translateY(0px);opacity: 100;transition: .5s ease-in-out;}
    .fade_out {transform: translateY(100px);opacity: 0;transition: 1s ease-in-out;}
    .fromLeft {transform: translateX(-100px);opacity: 0;transition: 1s ease-in-out;}
    .toCenter {transform: translateY(0px);opacity: 100;transition: 1s ease-in-out;}
    .opacity_on {opacity: 100;transition: 1.5s ease-in-out;}
    .opacity_off {opacity: 0;transition: 1.5s ease-in-out;}
    .delay-50 {transition-delay: .5s;}
    .delay-60 {transition-delay: .6s;}
    .delay-70 {transition-delay: .7s;}
    .delay-80 {transition-delay: .8s;}
    .margin-custom {
        margin-left:112px;
        margin-right:112px;
    }
</style>

<!-- <script type="module" src="https://unpkg.com/@dotlottie/player-component@1.0.0/dist/dotlottie-player.js"></script> -->
<!-- <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script>  -->
<script>


import lottie from 'lottie-web';
import wirelessData from '../assets/wirelessanimation.json';
import chartData from '../assets/chartanimation.json';
import Flickity from 'vue-flickity';
// import { PosAnimation } from 'leaflet';

export default {
    created() {
        
    },
    mounted() {
        // this.loadLottieAnimation();
        this.loadLottieAnimation('wirelessAnimation', this.wirelessData);
        this.loadLottieAnimation('chartAnimation', this.chartData);
        window.addEventListener('scroll', this.handleScroll);

        // Import Typed.js
        import('typed.js').then((module) => {
            const Typed = module.default;

            // Konfigurasi Typed.js
            const options1 = {
                strings: ["The Level Gauge measures the distance to the water surface using an industry-standard radar sensor.", "It is mounted above the water surface, which avoids costly and labour-intensive underwater operations.", "The 40 meter range of the Level Gauge will cover even the most extreme water level variations."],
                typeSpeed: 30,
                backSpeed: 0,
                showCursor: false,
                loop: true,
            };
            // Declare Typed variables using unique names
            const typed1 = new Typed(this.$refs.txt1, options1);
            this.typed1 = typed1;
        });

    },
    beforeDestroy() {
        // Hancurkan instance Typed saat komponen dihancurkan
        this.typed1.destroy();
    },
    destroyed() {
        window.addEventListener('scroll', this.handleScroll);
    },
    components: {
    Flickity,
    // PosAnimation
    },
    data() {
        
        return {
            // staticFilePath: process.env.VUE_APP_STATIC,
            wirelessData: wirelessData,
            chartData:chartData,
            isNavbarHidden:false,
            prevScrollPos:0,
            scrollPosOnFeatures:false,
            scrollPosOnUseCase:false,
            scrollPosOnContact:false,
            scrollPosOnFeature1:false,
            scrollPosOnFeature2:false,
            scrollPosOnFeature3:false,
            scrollPosOnFeature4:false,
            scrollPosOnFeature5:false,
            scrollPosOnFeature6:false,
            navigator:false,
            landingAnimationListEl: ['features', 'usecase', 'contact', 'feature-1', 'feature-2', 'feature-3', 'feature-4', 'feature-5', 'feature-6'],
            flickityOptions: {
                initialIndex: 3,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true
                
                // any options from Flickity can be used
            },
            charNumberLimit: 203,
            form_name: '',
            form_email: '',
            form_message: '',

            clickSubmitMessageBtn: false,


        }
    },
    methods: {
        handleInput(event) {
            if (event.target.value.length > this.charNumberLimit) {
                this.form_message = event.target.value.slice(0, this.charNumberLimit);
            }
        },
        cleanInputData() {
            // HTML ESCAPING
            const filter_name = new DOMParser().parseFromString(this.form_name, 'text/html');
            const filter_email = new DOMParser().parseFromString(this.form_email, 'text/html');
            const filter_message = new DOMParser().parseFromString(this.form_message, 'text/html');
            this.form_name = filter_name.body.textContent || '';
            this.form_email = filter_email.body.textContent || '';
            this.form_message = filter_message.body.textContent || '';
        },
        loadLottieAnimation(ref, animationData) {
            const options = {
                container: this.$refs[ref],
                animationData: animationData,
                renderer: 'svg',
                loop: true,
                autoplay: true,
            };
            // this.animation = lottie.loadAnimation(options)
            this[ref] = lottie.loadAnimation(options);
        },
        handleScroll() {
            const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
            this.isNavbarHidden = (currentScrollPos <= 300) ? false : (currentScrollPos > this.prevScrollPos)
            this.prevScrollPos = currentScrollPos;

            // get scroll top landing element effect 
            const landingAnimation = (el, type) => [(window.scrollY >= (el - 300)) ? true : false, type]
            for (const value of this.landingAnimationListEl) {
                let [scrollPos, type] = landingAnimation(document.getElementById(value).offsetTop, value);
                switch (type) {
                    case 'feature-1':
                        this.scrollPosOnFeature1 = scrollPos;
                        break;
                    case 'feature-2':
                        this.scrollPosOnFeature2 = scrollPos;
                        break;
                    case 'feature-3':
                        this.scrollPosOnFeature3 = scrollPos;
                        break;
                    case 'feature-4':
                        this.scrollPosOnFeature4 = scrollPos;
                        break;
                    case 'feature-5':
                        this.scrollPosOnFeature5 = scrollPos;
                        break;
                    case 'feature-6':
                        this.scrollPosOnFeature6 = scrollPos;
                        break;
                    case 'usecase':
                        this.scrollPosOnUseCase = scrollPos;
                        break;
                    case 'contact':
                        this.scrollPosOnContact = scrollPos;
                        break;
                }
            }
        },
        setNavigator(val) {
            this.navigator = val;
        },
        async sendMail() {
            if (!this.isEmailValid || !this.isNameValid) {
                this.clickSubmitMessageBtn = true;
                return false;
            }

            this.cleanInputData();
            try {
                const res = await this.axios.post('v1/apipublic/sendMail?token=sgsdgsfddggfdg',{
                    type:'contact_us',
                    name:this.form_name,
                    email:this.form_email,
                    message:this.form_message,
                })
                if (res.data.status == "OK") {
                    this.$snack.success({
                        text: "Sending...",
                    })
                }
            } catch (err) {
                console.error(err)
            }
        },
    },
    computed:{
        isEmailValid() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(this.form_email);
        },
        isNameValid() {
            return this.form_name.length > 0;
        }
    }
};
</script>